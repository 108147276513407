import { Box, Typography } from "@mui/material";

export function getFlexElInformationText() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">
          Med FlexEl har du en variabel elpris, der følger prisudviklingen på
          den nordiske elbørs, Nord Pool, time for time. Du har derfor mulighed
          for at flytte dit forbrug til de tidspunkter, hvor strømmen er
          billigst.
        </Typography>
        <Typography variant="body1" mt={2}>
          Den viste elpris for FlexEl er sidste måneds gennemsnitspris, og vil
          derfor være forskellig fra din kommende faktiske timepris, når din
          elaftale starter. Følg de{" "}
          <a
            href="https://norlys.dk/el/elpriser/"
            target="_blank"
            rel="noreferrer"
          >
            aktuelle priser
          </a>{" "}
          på norlys.dk eller i vores prisvindende{" "}
          <a
            href="https://norlys.dk/el/energi-app/"
            target="_blank"
            rel="noreferrer"
          >
            energi-app
          </a>
          .
        </Typography>

        <Box mt={4}>
          <Typography variant="body1bold">Elprisen</Typography>
          <Typography variant="body1" mt={2}>
            Elprisen for FlexEl beregnes på følgende måde:
          </Typography>
          <Typography component="ul" variant="body1" mt={2}>
            <li>Indkøbspris (Nord Pool timepris + handelsomkostninger)</li>
            <li>Fast tillæg til Norlys på 9 øre/kWh</li>
          </Typography>
          <Typography variant="body1" mt={2}>
            Med FlexEl får du dermed strøm til vores indkøbspris + et fast
            tillæg på 9 øre/kWh.
          </Typography>
          <Typography variant="body1" mt={2}>
            Handelsomkostningerne består af omkostninger forbundet med indkøb af
            strøm på elbørsen samt balance- og ubalanceomkostninger forbundet
            med forbrugsprognoser og difference i det faktiske forbrug.
            Handelsomkostningerne justeres automatisk to gange årligt til den 1.
            januar og 1. juli, så de svarer til de faktiske omkostninger. Du kan
            altid finde den aktuelle sats på{" "}
            <a
              href="https://norlys.dk/el/flexel"
              target="_blank"
              rel="noreferrer"
            >
              norlys.dk/el/flexel
            </a>
            .
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body1bold">Transport og afgifter</Typography>
          <Typography variant="body1" mt={2}>
            Transport og afgifter er obligatoriske at opkræve for alle
            elselskaber på vegne af dit lokale elnetselskab, Energinet og
            staten. Vi opkræver dig beløbet på deres vegne og videresender det
            uændret og uden gebyrer, fra dig til dem.
          </Typography>
          <Typography variant="body1" mt={2}>
            Det viste priseksempel på transport og afgifter er fra
            elnetselskabet N1. Dine endelige priser vil fremgå af din kontrakt.
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body1bold">Abonnement</Typography>
          <Typography variant="body1" mt={2}>
            Udover det månedlige abonnement på 29 kr. til Norlys, så betaler du
            abonnement til dit elnetselskab og Energinet.
          </Typography>
          <Typography variant="body1" mt={2}>
            <a
              href="https://norlys.dk/el/flexel/#video"
              target="_blank"
              rel="noreferrer"
            >
              Se mere om hvem du betaler til.
            </a>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body1bold">Samlet pris</Typography>
          <Typography variant="body1" mt={2}>
            Den samlede pris pr. kWh består af elprisen samt transport og
            afgifter til dit lokale elnetselskab, Energinet og staten. Den viste
            samlede pris pr. kWh er ekskl. abonnementer.
          </Typography>
          <Typography variant="body1" mt={2}>
            Den samlede pris forudsætter tilmelding til automatisk kortbetaling
            til 0 kr./regning. Betalingsservice kan vælges mod gebyr på 6,75
            kr./regning. Såfremt der ikke vælges betalingsform, betales gebyr 49
            kr./regning.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function getMånedsElInformationText() {
  return (
    <Box>
      <Box>
        <Typography variant="body1">
          Med MånedsEl har du en fast elpris, der er låst for en måned ad
          gangen. Det giver dig tryghed samtidig med, at prisen løbende
          afspejler udviklingen i markedet.
        </Typography>

        <Box mt={4}>
          <Typography variant="body1bold">Elprisen</Typography>
          <Typography variant="body1" mt={2}>
            Prisen på MånedsEl bliver reguleret hver måned. Den består af vores
            indkøbspris samt et fast tillæg. Indkøbsprisen fastsættes ud fra den
            pris, som strømmen i dit prisområde kan indkøbes til på elbørsen
            NASDAQ måneden forinden – baseret på det forventede forbrug.
            Derudover tillægges den et variabelt profiltillæg, som fastsættes på
            baggrund af den gennemsnitlige, forventede forbrugs- og prisprofil i
            dit område i den pågældende måned. Hertil kommer faktiske
            handelsomkostninger.
          </Typography>
          <Typography variant="body1" mt={2}>
            Handelsomkostningerne består af omkostninger forbundet med indkøb af
            strøm på elbørsen samt balance- og ubalanceomkostninger forbundet
            med forbrugsprognoser og difference i det faktiske forbrug.
            Handelsomkostningerne justeres automatisk to gange årligt til den 1.
            januar og 1. juli, så de svarer til de faktiske omkostninger. Du kan
            altid finde den aktuelle sats på{" "}
            <a
              href="https://norlys.dk/el/maanedsel"
              target="_blank"
              rel="noreferrer"
            >
              norlys.dk/el/maanedsel
            </a>
            .
          </Typography>
          <Typography variant="body1" mt={2}>
            Den viste elpris for MånedsEl er prisen for indeværende måned og kan
            variere fra din kommende faktiske månedspris, hvis din elaftale
            begynder i en senere måned. Elprisen bliver ændret 12 gange om året.
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body1bold">Transport og afgifter</Typography>
          <Typography variant="body1" mt={2}>
            Transport og afgifter er obligatoriske at opkræve for alle
            elselskaber på vegne af dit lokale elnetselskab, Energinet og
            staten. Vi opkræver dig beløbet på deres vegne og videresender det
            uændret og uden gebyrer, fra dig til dem.
          </Typography>
          <Typography variant="body1" mt={2}>
            Det viste priseksempel på transport og afgifter er fra
            elnetselskabet N1. Dine endelige priser vil fremgå af din kontrakt.
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body1bold">Abonnement</Typography>
          <Typography variant="body1" mt={2}>
            Udover det månedlige abonnement på 29 kr. til Norlys, så betaler du
            abonnement til dit elnetselskab og Energinet.
          </Typography>
          <Typography variant="body1" mt={2}>
            <a
              href="https://norlys.dk/el/maanedsel/#video"
              target="_blank"
              rel="noreferrer"
            >
              Se mere om, hvem du betaler til.
            </a>
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body1bold">Samlet pris</Typography>
          <Typography variant="body1" mt={2}>
            Den samlede pris pr. kWh består af elprisen samt transport og
            afgifter til dit lokale elnetselskab, Energinet og staten. Den viste
            samlede pris pr. kWh er ekskl. abonnementer.
          </Typography>
          <Typography variant="body1" mt={2}>
            Den samlede pris forudsætter tilmelding til automatisk kortbetaling
            til 0 kr./regning. Betalingsservice kan vælges mod gebyr på 6,75
            kr./regning. Såfremt der ikke vælges betalingsform, betales gebyr 49
            kr./regning.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export function getKombiElInformationText() {
  return (
    <Box>
      <Box>
        <Typography variant="body1bold">Bliv klogere på KombiEl</Typography>
        <Typography variant="body1" mt={1}>
          Med KombiEl har du mulighed for at udnytte fordelene ved både en fast
          og variabel timepris. Derfor er KombiEl et godt valg til dig, som
          ønsker en elaftale, hvor en del af prisen varierer, mens du samtidig
          er sikret en fast pris, der minimerer store prisudsving.
        </Typography>
        <Typography variant="body1" mt={2}>
          KombiEl giver dig en elpris, hvor halvdelen af dit forbrug afregnes
          til en fast pris, og den anden halvdel til en variabel timepris. Du
          kan derfor med fordel forsøge at flytte fx tøjvasken til de billigste
          timer, mens du samtidig er sikret mod større prishop i den forkerte
          retning, fordi halvdelen af din elpris er fast et kvartal ad gangen.
        </Typography>
        <Typography variant="body1" mt={2}>
          Din elpris følger løbende markedsudviklingen både på kort og lang
          sigt.
        </Typography>
        <Typography variant="body1" mt={2}>
          <a
            href="https://norlys.dk/el/kombiel/#video"
            target="_blank"
            rel="noreferrer"
          >
            Se video om KombiEl
          </a>
        </Typography>
        <Typography variant="body1" mt={2}>
          <a
            href="https://norlys.dk/el/elpriser/#prisudvikling"
            target="_blank"
            rel="noreferrer"
          >
            Se den historiske prisudvikling for KombiEl
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export function getChargingStandEjeInformationText() {
  return (
    <Box>
      <Box>
        <Typography variant="body1bold">
          Bliv klogere på din ladeløsning
        </Typography>
        <Typography variant="body1" mt={1}>
          Hos Norlys får du en intelligent ladeboks, som er nem at betjene. Du
          vælger selv, om du vil leje eller købe din ladeboks.
        </Typography>
        <Typography variant="body1" mt={2}>
          Hvis du lejer din ladeboks, er startprisen lav, og derefter har du en
          fast, månedlig lejepris. Køber du i stedet ladeboksen, er din
          startpris højere end ved leje, men din månedlige pris er lavere.
        </Typography>
      </Box>
    </Box>
  );
}

export function getChargingStandLejeInformationText() {
  return (
    <Box>
      <Box>
        <Typography variant="body1bold">
          Bliv klogere på din leje ladeløsning
        </Typography>
        <Typography variant="body1" mt={1}>
          Hos Norlys får du en intelligent ladeboks, som er nem at betjene. Du
          vælger selv, om du vil leje eller købe din ladeboks.
        </Typography>
        <Typography variant="body1" mt={2}>
          Hvis du lejer din ladeboks, er startprisen lav, og derefter har du en
          fast, månedlig lejepris. Køber du i stedet ladeboksen, er din
          startpris højere end ved leje, men din månedlige pris er lavere.
        </Typography>
      </Box>
    </Box>
  );
}
