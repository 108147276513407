import {
  Accordion,
  AccordionMinimumHeight,
  ButtonColorEnum,
  InputComponent,
  ValidationTypeEnum,
  Modal,
} from "norlys-ui-components";
import IStepInterface from "../../../../../Interfaces/IStepInterface";
import { IInputValidationResult } from "norlys-ui-components/lib/components/input-component/Input-Component";
import "./NameCprStep.scss";
import Column from "../../../../Utils/Column/Column";
import { useEffect, useState, useContext } from "react";
import { ValidateInput } from "../../../../../Services/ValidationService/ValidationService";
import PartnerContentContext from "../../../../../Contexts/PartnerContentContext";
import CallMeUpComponent from "../../../../SharedComponents/CallMeUpComponent/CallMeUpComponent";
import { StepEnum } from "../../../../../Models/StepEnum";
import { InfoTooltip } from "../../../../Utils/UI/InfoBadge/InfoBadge";
import { Box } from "@mui/material";

const NameAddressStep = ({
  nextStep,
  previousStep,
  order,
  open,
  toggleOpen,
  setOrder,
  datahubAddress,
  setErrorMode,
}: IStepInterface) => {
  const [allowNext, setAllowNext] = useState(true);
  const [idqTries, setIdqTries] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [cprError, setCprError] = useState("");
  const partnerContentContext = useContext(PartnerContentContext);
  const DOMPurify = require("dompurify")(window);
  const [existingCustomerModalOpen, setExistingCustomerModalOpen] =
    useState(false);

  useEffect(() => {
    setExistingCustomerModalOpen(order.existingCustomer);
  }, [order.existingCustomer]);

  const handleCprChange = (val: IInputValidationResult): any => {
    if (val.valid) {
      setOrder({
        ...order,
        cpr: val.value,
        IDQValid: false,
        DatahubValid: false,
        existingCustomer: false,
      });
    } else {
      setOrder({
        ...order,
        cpr: "",
        IDQValid: false,
        DatahubValid: false,
        existingCustomer: false,
      });
    }
    setAllowNext(true);
  };

  const handleNameChange = (val: IInputValidationResult): any => {
    if (val.valid) {
      setOrder({
        ...order,
        fullName: val.value,
        IDQValid: false,
        DatahubValid: false,
        existingCustomer: false,
      });
    } else {
      setOrder({
        ...order,
        fullName: "",
        IDQValid: false,
        DatahubValid: false,
        existingCustomer: false,
      });
    }
    setAllowNext(true);
  };

  const formValid = () => {
    return !!order.cpr && !!order.fullName && allowNext;
  };

  const resolveValidation = (
    iDQResult: any,
    datahubResult: any,
    sonwinResult: any
  ) => {
    order.IDQValid =
      iDQResult.value.data === "Valid" || iDQResult.value.status === 500;
    order.DatahubValid =
      datahubResult.value.data?.validationResult === "Valid" ||
      datahubResult.value.status === 500;
    order.InDelivery =
      sonwinResult.value.data?.inDelivery && sonwinResult.value.status !== 500;
    order.InBinding =
      sonwinResult.value.data?.bindingDate !== "" &&
      sonwinResult.value.status !== 500;
    setIsLoading(false);

    if (
      datahubResult.value.data?.validationResult === "NoMeteringPointsFound"
    ) {
      setAllowNext(false);
      setErrorMode("invalid-meteringpoint");
    }

    if (iDQResult.value.status === 403) {
      setAllowNext(false);
      setCprError("IDQError");
    } else if (
      order.IDQValid &&
      order.DatahubValid &&
      !order.InBinding &&
      (!order.InDelivery ||
        (partnerContentContext.resaleEnabled && order.InDelivery))
    ) {
      setCprError("");
      nextStep();
    } else if (!order.IDQValid) {
      setIdqTries(idqTries + 1);
      setAllowNext(false);
      if (idqTries >= 2) {
        setCprError("IDQError");
      }
    } else if (!order.DatahubValid) {
      nextStep();
    } else if (
      order.InBinding ||
      (!partnerContentContext.resaleEnabled && order.InDelivery)
    ) {
      if (partnerContentContext.chargingStandProductIds.length === 0) {
        setErrorMode("ExistingCostumer");
      } else {
        setOrder({ ...order, existingCustomer: true });
      }
    } else {
      setErrorMode("FAQ");
    }
  };

  const ValidateAndNext = async () => {
    if (order.existingCustomer) nextStep();
    setIsLoading(true);
    ValidateInput(
      order.cpr,
      order.fullName,
      datahubAddress,
      order.deliveryAddressId,
      resolveValidation,
      false,
      partnerContentContext.partnerId
    );
  };

  const retry = () => {
    setIdqTries(0);
    setCprError("");
  };

  return (
    <div>
      <Accordion
        loading={isLoading}
        loadingText={partnerContentContext.stepLoader.text}
        loadingTitle={partnerContentContext.stepLoader.title}
        label={partnerContentContext.cprStep.title}
        open={open}
        click={toggleOpen}
        firstButtonLabel={
          partnerContentContext.cprStep.backButton
            ? partnerContentContext.cprStep.backButton
            : "Tilbage"
        }
        firstButtonOutline={true}
        firstButton={previousStep}
        secondButtonLabel={
          partnerContentContext.cprStep.nextButton
            ? partnerContentContext.cprStep.nextButton
            : "Næste"
        }
        secondButtonDisabled={!formValid()}
        secondButtonType={
          formValid() ? ButtonColorEnum.DARK_GREEN : ButtonColorEnum.GREY
        }
        secondButton={ValidateAndNext}
        minimumHeight={AccordionMinimumHeight.SMALL}
        firstButtonId="NameCprStepReturn"
        secondButtonId="NameCprStepForward"
        secondButtonShadow={true}
      >
        <Column fullWidth={true}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InputComponent
              width={600}
              placeholder={
                partnerContentContext.cprStep.namePlaceholder
                  ? partnerContentContext.cprStep.namePlaceholder
                  : "Navn"
              }
              validationType={ValidationTypeEnum.NAME}
              change={handleNameChange}
            />
            <Box sx={{ padding: 0, margin: "0 0 0 1rem" }}>
              <InfoTooltip
                placement="right"
                message="Skriv dit navn, som det står på dit sundhedskort (sygesikringskort)."
              />
            </Box>
          </Box>
        </Column>
        <Column fullWidthMobile={true}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <InputComponent
              width={600}
              placeholder={
                partnerContentContext.cprStep.cprPlaceholder
                  ? partnerContentContext.cprStep.cprPlaceholder
                  : "CPR-nummer"
              }
              validationType={ValidationTypeEnum.CPR_AGE}
              change={handleCprChange}
            />
            <Box sx={{ padding: 0, margin: "0 0 0 1rem" }}>
              <InfoTooltip
                placement="right"
                message="Norlys Energi bruger dit CPR-nummer til kreditvurdering og oprettelse af dig som kunde. Du kan finde mere information i de gældende betingelser"
              />
            </Box>
          </Box>
        </Column>
        {!allowNext && !order.IDQValid && (
          <div className="error-section">
            {partnerContentContext.cprStep.idqUnvalid}
          </div>
        )}
      </Accordion>
      <Modal
        visible={cprError !== ""}
        onClosing={() => {
          retry();
        }}
      >
        <div className="outer-container">
          <h1 className="headerText">{partnerContentContext.idqError.title}</h1>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(partnerContentContext.idqError.text),
            }}
          ></div>
          <CallMeUpComponent
            order={order}
            address={datahubAddress}
            showSecondButton={false}
            idqError={true}
            step={StepEnum.CprStep}
          />
        </div>
      </Modal>
      <Modal
        visible={existingCustomerModalOpen}
        onClosing={() => setExistingCustomerModalOpen(false)}
      >
        <div className="outer-container">
          <h1 className="headerText">Eksisterende kunde</h1>
          <p style={{ fontSize: "18px" }}>
            Det ser ud som om du allerede har en el-aftale hos Norlys, vi kan
            derfor ikke tilbyde rabat på køb af el-aftale. Du vil stadig modtage
            rabat på køb af andre produkter.
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default NameAddressStep;
